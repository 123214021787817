<template>
  <div>
    <a-modal
      :visible="isShow"
      title="详情"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
      width="90%" :dialog-style="{ top: '50px' }"
    >
      <template slot="footer">
        <div class="footerBtn">
          <span class="stamps">
            <a-button class="clearBtn" @click="printHandle">打印客单</a-button>
            <a-button class="clearBtn" @click="printJie" style="marginLeft:1vw" v-if="da && (da.pay_status===30 || da.pay_status===20 )">打印结账单</a-button>
            <a-button class="clearBtn" @click="handleMenuClick" v-if="da && da.pay_status===30">销账</a-button>
          </span>
          <span>
            <span class="cancel">
              <a-button key="back" class="clearBtn" @click="handleCancel"> 取消 </a-button>
            </span>
            <span class="primary">
              <a-button class="submit OkBtn" @click="handleOk">
                确定
              </a-button>
            </span>
          </span>
        </div>
      </template>
      <div>
        <div class="first">
          <b class="bzero">订单号:{{da && da.order_no}}</b>
          <b class="bone" v-if="FOOD_TYPE == 'feast'">桌台: {{da && da.table_name}}</b>
          <b class="btwo" v-if="da && da.order_clerk.length>0">导购:
              <span v-for="(item,index) in da.order_clerk" :key="index"> {{item}}</span>
             </b>
          <b class="bthree">状态: 
            <span v-if="da && da.pay_status===10"> 未结算</span>
            <span v-if="da && da.pay_status===20"> 完成</span>
            <span v-if="da && da.pay_status===60"> 已退款</span>
            <span v-if="da && da.pay_status===30"> 已结算-挂账</span>   
          </b>
        </div>
        <div class="second">
          <p class="pzero" v-if="da && da.pay_status===20">时间：{{da && da.order_time}}</p>
          <p class="pone" v-if="da && da.pay_status===30">挂账时间：{{da && da.hang_time}}</p>
          <p class="pzero" v-if="da && da.pay_status===60 && refundDetail && refundDetail.base">退款人:&nbsp; {{refundDetail.base.refund_name}} 电话:&nbsp; {{refundDetail.base.refund_mobile}}</p>
          <p class="pone" v-if="da && da.pay_status===60 && refundDetail && refundDetail.base">退款时间: &nbsp;{{da && refundDetail.base.refund_time}}</p>
          <p class="pzero" v-if="da && da.pay_status===30"><span v-if="da && da.hang_name">联系人: </span>{{da && da.hang_name}} 电话:  {{da && da.hang_mobile}}</p>
          <p class="pone" v-if="da && (da.pay_status===20 || da.pay_status===60) && da.paylog.length>0"> 支付方式:&nbsp;
            <span v-for="(item,index) in da.paylog " :key="index" class="pay">
              <span class="jia">+</span> 
              {{item.type_desc}}({{item.payed_price ? Number(item.payed_price).toFixed(2) : ''}})
              
            </span>
            <span class="type" v-if="da && da.discount_price!=0.00">折扣({{da && da.discount_price}})</span>
            <span class="type" v-if="da && da.free_price!=0.00">优免({{da && da.free_price}})</span>
            <span class="type" v-if="da && da.maling_price!=0.00">抹零({{da && da.maling_price}})</span>
          </p>
        </div>
        <div>
          <p class="ptwo" v-if="da &&(da.refund_price * 1 > 0)">
            退款方式:
            <span v-for="(item,index) in refundDetail.paylog " :key="index">
              <span class="jia" v-if="index > 0">+</span>
              {{item.type_desc}}({{item.payed_price ? Number(item.payed_price).toFixed(2) : ''}})
            </span>
          </p>
        </div>
        <div class="third"  v-if="da && da.pay_status===60 && refundDetail && refundDetail.base"><b>退款金额: {{refundDetail.base.refund_price}}</b></div>
        <div class="third" v-if="da && da.pay_status===20">
          <b style="width:33%;display:inline-block" v-if="da && da.pay_status===20">实收: {{da && da.payed_price}}</b>
          <b style="width:33%;display:inline-block" v-if="da && (da.refund_price * 1 > 0)">已退款: {{da && da.refund_price}}</b>
        </div>
        <div class="third" v-if="da && da.pay_status===30"><b>应收: {{da && da.hang_price}}</b></div>
        <div v-if="da && da.pay_status===20"><p  class="ptwo" v-if="da && da.remark">桌台备注: {{da && da.remark}}</p></div>
      </div>
      <div v-if="da && da.pay_status===60">
        <a-table
          :columns="columns1"
          :data-source="orderGoods"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          bordered
          style="width: 100%; margin-top: 1vh"
           :pagination="{
              current:current,
            }"
             @change="handleTableChange"
             :locale="{emptyText: '暂无数据'}"
        >
          <span slot="spec_content" slot-scope="text" > 
            <span v-if="text">/  {{text}}  /</span>
          </span>
          <span slot="total_num" slot-scope="record" >       
             x{{record.total_num}}
          </span>
          <span slot="refund_total_num" slot-scope="text" >       
             x{{text}}
          </span>
          <span slot="total_price" slot-scope="text" >       
             ￥{{text}}       
          </span>
          <span slot="refund_price" slot-scope="text" >       
             ￥{{text}}       
          </span>
        </a-table>
      </div>
      <div v-else>
        <a-table
          :columns="columns"
          :data-source="da.orderGoods && da.orderGoods"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          bordered
          style="width: 100%; margin-top: 1vh"
           :pagination="{
              current:current,
            }"
             @change="handleTableChange"
             :locale="{emptyText: '暂无数据'}"
        >
          <span slot="spec_content" slot-scope="text" > 
            <span v-if="text">/  {{text}}  /</span>
          </span>
          <span slot="total_num" slot-scope="text" >       
             x{{text}}       
          </span>
          <span slot="total_price" slot-scope="text" >       
             ￥{{text}}       
          </span>
          <span slot="total_pay_price" slot-scope="text" >       
             ￥{{text}}       
          </span>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { refundOrderDetail } from '@/request/bill'
import Cookies from "js-cookie";
const columns = [
  {
    title: "商品",
    dataIndex: "goods_name",
    ellipsis: true,
  },
  {
    title: "规格",
    dataIndex: "spec_content",
    ellipsis: true,
    scopedSlots: { customRender: "spec_content" },
  },
  {
    title: "数量",
    dataIndex: "total_num",
    ellipsis: true,
    scopedSlots: { customRender: "total_num" },
  },
  {
    title: "原价",
    dataIndex: "total_price",
    ellipsis: true,
    scopedSlots: { customRender: "total_price" },
  },
  {
    title: "口味",
    dataIndex: "taste",
  },
  {
    title: "忌口",
    dataIndex: "avoid",
    ellipsis: true,
  },
  {
    title: "做法",
    dataIndex: "practice",
    ellipsis: true,
  },
  // {
  //   title: "实收",
  //   dataIndex: "total_pay_price",
  //   scopedSlots: { customRender: "total_pay_price" },
  // },
];

let columns1 = [
  {
    title: "商品",
    dataIndex: "goods_name",
    ellipsis: true,
  },
  // {
  //   title: "规格",
  //   dataIndex: "spec_content",
  //   ellipsis: true,
  //   scopedSlots: { customRender: "spec_content" },
  // },
  {
    title: "数量",
    key: "total_num",
    ellipsis: true,
    scopedSlots: { customRender: "total_num" },
  },
  {
    title: "原价",
    dataIndex: "total_price",
    ellipsis: true,
    scopedSlots: { customRender: "total_price" },
  },
  {
    title: "口味",
    dataIndex: "taste",
  },
  {
    title: "忌口",
    dataIndex: "avoid",
    ellipsis: true,
  },
  {
    title: "做法",
    dataIndex: "practice",
    ellipsis: true,
  },
  {
    title: "退货数量",
    dataIndex: "refund_total_num",
    ellipsis: true,
    scopedSlots: { customRender: "refund_total_num" },
  }
];

export default {
  props:['isShow','da',],
  data() {
    return {
      columns,
      columns1,
      current:1, //表格当前页
      refundDetail:{},
      orderGoods : [],
      FOOD_TYPE:''
    };
  },
  updated(){
  },
  watch:{
    isShow(val){
      if(val){
        if(this.da && this.da.refund_price * 1 > 0){
          refundOrderDetail({order_id: this.da.order_id}).then(res => {
            if(res.data.status == 200){
              this.refundDetail = res.data.data
              this.orderGoods = res.data.data.orderRefundLog
            }
          })
        }
      }
    }
  },
  mounted(){
    // console.log('11111')
    this.FOOD_TYPE = localStorage.getItem('FOOD_TYPE') || 'feast'
  },
  methods: {
    handleCancel() {
      this.current=1
      this.$emit('choose',false)
    },
    handleOk() {
       this.current=1
      this.$emit('choose',false)
    },
    handleTableChange(e){ //点击分页
       this.current = e.current;
    },
    handleMenuClick(){
      this.$emit('changemo',this.da)
    },
    printHandle(){
      this.$emit('printHandle',this.da)
    },
    printJie(){
      const foundingLoId=this.da && this.da.founding_log_id;
      this.socketApi.websocketsend({"key": "jiezhangAction",value:{token: Cookies.get("Access-Token"),founding_log_id:foundingLoId} }); 
    }
  },
};
</script>
 <style lang="less" scoped>
.first{
  display: flex;
  align-items: center;
  font-size: 1.8vh;
  >b{
    width: 33%;
  }
  .bthree {
    color: #3293ff;
  }
}
.second {
  display: flex;
  margin-top: 1vh;
  color: #a6a6a6;
  font-size: 1.4vh;
  >p{
    width: 33%;
  }
  .pone{
    // width:50%;
  }
}
.ptwo {
  font-size: 1.4vh;
  color: #a6a6a6;
}
.third {
  width:100%;
  font-size: 1.8vh;
  color: #ff3b30;
}
/deep/.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 0;
  font-size: 1.8vh;
  text-align: center;
}
/deep/.ant-table-tbody > tr > td {
  padding: 8px 8px;
  font-size: 1.6vh;
}
/deep/.ant-table-wrapper {
  height: 55vh;
}
.footerBtn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stamps{
  overflow: hidden;
}
.stamp {
  // width: 12vh;
  // height: 3.9vh;
  // float: left;
}
.submit {
  margin: 0 0 0 3vh;
}
.pay:first-child .jia{
   display: none !important;
}
.type{
  margin-left: 2vw;
}
</style>