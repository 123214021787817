<template>
  <div>
    <a-modal
      :visible="isShow"
      title="销账"
      cancel-text="取消"
      ok-text="确定"
      @cancel="handlecancel"
    >
      <template slot="footer">
        <div class="footerBtn">
          <a-button class="clearBtn" @click="handlecancel">取消</a-button>
          <a-button class="OkBtn" @click="handleOk">确定</a-button>
        </div>
      </template>
      <p>挂账金额：￥{{da.hang_price}}</p>
       <!-- <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
       >
         <a-form-model-item ref="name" label="销账方式" prop="name">
          <a-select v-model="form.name"  placeholder="please select your zone">
            <a-select-option v-for="(item, index) in items" :key="index" :value="item.type_desc">
              {{item.type_desc }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
       </a-form-model> -->
      <a-form-model 
         ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
     >
         <a-form-model-item label="销账方式" prop="region">
      <a-select v-model="form.region" placeholder="请选择销账方式">
          <a-select-option v-for="(item, index) in items" :key="index" :value="item.type_name">
              {{item.type_desc }}
            </a-select-option>
          </a-select>
    </a-form-model-item>
     </a-form-model> 
      <p>支付金额：￥{{da.hang_price}}</p>
    </a-modal>
  </div>
</template>
<script>
import {hangOrder} from '@/request/bill.js';
import {payType} from '@/request/business.js';
export default {
  props:['isShow','id','da'],
  data() {
    return {
      items: [{
                "id":3,
                "type_desc":"现金",
                "type_name":"cash",
                "is_use":1,
                "state":1,
                "update_time":"2021-11-22 18:45:07",
                "create_time":"2021-11-22 18:45:07"
      },],
      labelCol: { span: 5},
      wrapperCol: { span: 6},
      form:{
          region: undefined,
        },
       rules: {
        region: [
          { required: true, message: '请选择销账方式', trigger: 'change' },
       
        ],
       }
    };
  },
  mounted(){
    // this.payTypeRequest()
  },
  updated(){
   
  },
  methods: {
    handlecancel() { 
       this.$emit('choose',true,true); 
      //  this.form.region="";    
    },
    handleOk() {
       this.$refs.ruleForm.validate(valid => {
        if (valid) {
           this.hangOrderRequest()
        } else {
         
          return false;
        }
      });
     
    },
    payTypeRequest(){ //支付方式列表
      payType()
        .then((res) => {
          if(res.data.status===200){
            const list = res.data.data;
            this.items=list;
          }                   
          })
        .catch(result => {
            return false;
          })
    },
    hangOrderRequest(){
      const _this=this;
       hangOrder({
         order_id:this.id,//订单id
         pay_type:this.form.region,//支付方式
       })
        .then((res) => {
          console.log(res.data.status)
          if(res.data.status===200){
            _this.$message.success('销账成功',1.5)    
            _this.$emit('choose',true,true);  
            //  _this.form.region="";      
          }                   
          })
        .catch(result => {
            return false;
      })
    }
  },
};
</script>
 <style lang="less" scoped>
.ant-select{
    margin-bottom: 1em;
}
/deep/.ant-form-item-control {
  width:10vw;
  margin-left:0.5vw;
}

p{
  font-size: 1.8vh;
  color:#505050;
}
/deep/.ant-form-item-required{
  font-size: 1.8vh;
}
.footerBtn{
  text-align: center;
}
/deep/.ant-select-selection--single{
 height: 3.9vh;
  line-height: 3.9vh;
}
/deep/.ant-select-selection__rendered{
 font-size: 1.8vh;
  height: 3.9vh;
  line-height: 3.9vh;
}
</style>