<template>
  <div>
    <a-modal
      :visible="isShow"
      title="详情"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <span class="stamp">
          <a-button class="clearBtn"> 打印此单 </a-button>
        </span>
        <span>
          <a-button class="OkBtn"> 销账 </a-button>
        </span>
        <span class="cancel">
          <a-button key="back" class="clearBtn" @click="handleCancel"> 取消 </a-button>
        </span>
        <span class="primary">
          <a-button class="submit OkBtn" type="primary" @click="handleOk">
            确定
          </a-button>
        </span>
      </template>
      <div>
        <div class="first">
          <b class="bzero">订单号: 111111111111111111</b>
          <b class="bone">桌台: 龙腾四海</b>
        </div>
        <div class="second">
          <p class="pzero">联系人: 张三 电话: 15901512299</p>
          <div>
            <p class="pone">F挂账时间: 2021-12-22 18.00</p>F</div>          
        </div>
        <div class="third">
          <b>应收: 88.00</b>
          <b class="bthree">状态: 挂账</b>
        </div>
      </div>
      <div>
        <a-table
          :columns="columns"
          :data-source="members"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          bordered
          style="width: 100%; margin-top: 1vh"
        >
        </a-table>
      </div>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: "商品",
    dataIndex: "111",
    ellipsis: true,
  },
  {
    title: "数量",
    dataIndex: "222",
    ellipsis: true,
  },
  {
    title: "原价",
    dataIndex: "333",
    ellipsis: true,
  },
  {
    title: "口味",
    dataIndex: "444",
  },
  {
    title: "忌口",
    dataIndex: "555",
    ellipsis: true,
  },
  {
    title: "做法",
    dataIndex: "666",
    ellipsis: true,
  },
  {
    title: "实收",
    dataIndex: "777",
  },
];
const members = [
  {
    111: "奶茶（赠）",
    222: "×1",
    333: "￥20.00",
    444: "",
    555: "",
    666: "",
    777: "￥20.00",
  },
  {
    111: "矿泉水（退）",
    222: "×1",
    333: "￥20.00",
    444: "",
    555: "",
    666: "",
    777: "￥20.00",
  },
  {
    111: "牛肉面",
    222: "×2",
    333: "￥20.00",
    444: "",
    555: "",
    666: "",
    777: "￥20.00",
  },
  {
    111: "雪碧",
    222: "×4",
    333: "￥20.00",
    444: "",
    555: "",
    666: "",
    777: "￥20.00",
  },
  {
    111: "奶茶（退）",
    222: "×10",
    333: "￥20.00",
    444: "",
    555: "",
    666: "",
    777: "￥20.00",
  },
  {},
  {},
  {},
  {},
  {},
];
export default {
  data() {
    return {
      members,
      columns,
      isShow: false,
    };
  },
  methods: {
    handleCancel() {
      this.isShow = false;
    },
    handleOk(e) {
      console.log(e);
      this.isShow = false;
    },
  },
};
</script>
 <style lang="less" scoped>
/deep/.ant-modal-content {
  position: fixed;
  width: 79vw;
  height: 90vh;
  left: 19vw;
  top: 7vh;
}
.first {
  display: flex;
}
.second {
  display: flex;
  margin-top: 4vh;
  color: #a6a6a6;
}
.bzero {
  font-size: 1.8vh;
  position: fixed;
}
.bone {
  font-size: 1.8vh;
  position: fixed;
  margin-left: 30vh;
}
.btwo {
  font-size: 1.8vh;
  position: fixed;
  margin-left: 50vh;
}
.bthree {
  font-size: 1.8vh;
  color: #3293ff;
  margin-left: 2vh;
}
.pzero {
  font-size: 1.4vh;
  position: fixed;
}
.pone {
  font-size: 1.4vh;
  position: fixed;
  margin-left: 30vh;
}
.third {
  font-size: 1.8vh;
  color: #ff3b30;
  margin-top: 3.5vh;
}
/deep/.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 0;
  font-size: 1.8vh;
  text-align: center;
}
/deep/.ant-table-tbody > tr > td {
  padding: 8px 8px;
  font-size: 1.6vh;
}
/deep/.ant-table-wrapper {
  height: 55vh;
}
/deep/.ant-btn {
  font-size: 1.8vh;
  width: 17vh;
  height: 5vh;
}
/deep/.ant-modal-footer{
  margin-top: -3vh;
}

.writeOff {
  width: 10vh;
  height: 3.9vh;
  color: #ffffff;
  background-color: #3293ff;
  border: #3293ff;
  float: left;
  margin-left: 3.5vh;
  border-radius: 4px;
}
.submit {
  margin: 0 0 0 3.5vh;
}
</style>