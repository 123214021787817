<template>
  <div>
    <a-modal
      :visible="isShow"
      title="反结账"
      @cancel="handlecancel"
    >
      <template slot="footer">
        <div class="footerBtn">
          <a-button class="clearBtn" @click="handlecancel">取消</a-button>
          <a-button class="OkBtn" @click="handleOk">确定</a-button>
        </div>
      </template>
      <h3>
        反结账，将会把此账单
        <p>变更为待结算状态</p>
        ，是否继续？
      </h3>
    </a-modal>
  </div>
</template>
<script>
import {antiOrder} from '@/request/bill.js';
export default {
  props:['isShow','id'],
  data() {
    return {
    };
  },
  methods: {
    handlecancel() {
      this.$emit('choose',true,false);    
    },
    handleOk() {
      this.antiOrderRequest()   
    },
    antiOrderRequest(){
      const _this=this;
       antiOrder({
         order_id:_this.id
        })
        .then((res) => {
            if(res.data.status===200){
                this.$emit('choose',true,true); 
              }       
            })
            .catch(result => {
              return false;
          })
    }
  },
};
</script>
 <style lang="less" scoped>
h3 {
  font-size: 1.8vh;
  display: flex;
  p{
    font-size: 1.8vh;
    color: red;
  }
}
.footerBtn{
  text-align: center;
}
</style>