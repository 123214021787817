<template>
  <div id="hy">
    <!-- <touTop :headername="headerName" /> -->
    <div class="mis">
      <div class="left">
        <!-- <sider :headername="headerName" :sideMenus="sideMenu" /> -->
      </div>
      <div class="right">
        <breadCrumb :siderContent="siderName" />
        <div class="searchForm">
          <span  class="input">
            <a-input  placeholder="订单号/桌台号" v-model="order_no" />
          </span>
          <a-select default-value="订单状态" @change="handleChange" allowClear placeholder="订单状态">
            <a-select-option
              v-for="(item, index) in items"
              :key="index"
              :value="item.key"
              >{{ item.name }}</a-select-option
            >
          </a-select>
          <a-select default-value="桌台名称" @change="tableIDChoose" allowClear placeholder="桌台名称">
            <a-select-option
              v-for="(item, index) in tableList"
              :key="index"
              :value="item.id"
              >{{ item.table_name }}</a-select-option
            >
          </a-select>
          <!-- <span>时间： </span> -->
          <a-range-picker
            @change="onChange" valueFormat="YYYY-MM-DD"
            :placeholder="['开始日期', '结束日期']"
            v-model="dateArr"
          >
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
          <span class="input">
              <a-input v-if="FOOD_TYPE == 'feast'"
                placeholder="请输入挂账人手机号"
                v-model="hang_mobile"
              />
          </span>
        
          <a-button class="searchBtn" @click="searchMember">查询</a-button>

          <br />
        </div>
        <div>
          <a-table
            :columns="columns"
            :data-source="members"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            bordered
            style="width: 100%; margin-top: 1vh"
            :pagination="{
              current: current,
              total: total,
              pageSize: 10,
            }"
            @change="handleTableChange"
            :locale="{emptyText: '暂无数据'}"
          >
            <!-- <span slot="payed_price" slot-scope="text">
              <span class="textColor">￥{{text}}</span>
            </span> -->
            <span slot="status" slot-scope="text">
              <span v-if="text === 10"> 未结算</span>
              <span v-if="text === 20"> 已结算</span>
              <span v-if="text === 60"> 已退款</span>
              <span v-if="text === 30"> 已结算-挂账</span>
            </span>
            <span slot="payed_price" slot-scope="text, record">
              <div v-for="(item, index) in record.paylog" :key="index">
                <span>{{ item.type_desc }}：</span>
                <span v-if="item.payed_price" class="textColor">￥{{ item.payed_price.toFixed(2) }}</span>
              </div>
            </span>
            <span slot="action" slot-scope="text, record" class="textColor gray">
              <div style="text-align:center">
                <span
                  :class="[record.is_anti === 0 ? 'active' : 'isclick', 'button']"
                  @click="changeUncheckout(record)"
                >
                  <span v-if="FOOD_TYPE == 'feast'"> 反结账 </span>
                  <span class="heng" v-if="record.is_anti === 0 && FOOD_TYPE == 'feast'">————</span>
                </span>
                <span  v-if="permission.indexOf('/clerk/order/refundOrder') > -1"
                  :class="[
                    record.is_refund === 0 ? 'active' : 'isclick',
                    'button',
                  ]"
                  @click="refundHandle(record)"
                >
                  <span> 退款 </span>
                  <span class="heng" v-if="record.is_refund === 0">———</span>
                </span>
                <span
                  :class="[record.is_hang === 0 ? 'active' : 'isclick', 'button']"
                  @click="changeWriteoff(record)"
                >
                  
                  <span v-if="FOOD_TYPE == 'feast'"> 挂账核销 </span>
                  <span class="heng" v-if="record.is_hang === 0 && FOOD_TYPE == 'feast'">—————</span>
                </span>
                <a-divider type="vertical" />
                <a class="clicks" @click="printKedan(record)"> 打印 </a>
                <a class="click" @click="clickDetail(record)"> 详情 </a>
              </div>
              
            </span>
            <span slot="order_clerk" slot-scope="text, record">
              <div v-for="(item, index) in record.order_clerk" :key="index">
                {{ item }}
              </div>
            </span>
          </a-table>
        </div>
      </div>
    </div>
    <uncheckoutModal
      :isShow="showUncheckout"
      :id="order_id"
      @choose="changeUn(arguments)"
    />
    <writeoffModal
      v-if="showWriteoff"
      :isShow="showWriteoff"
      :id="order_id"
      @choose="changeWriteo(arguments)"
      :da="record"
    />
    <!-- <ruleModal /> -->
    <paymentModal />
    <drawbackModal
      :isShow="showDrawback"
      :da="record"
      @choose="changeModal"
      @changemo="changeModalStatu"
      @printHandle="printKedan"
    />
    <refundModal @choose="clooseRefund" :isShow="showRefund" :orderData="record"/>
  </div>
</template>
<script>
import { billList } from "@/request/bill.js";
import { refundOrderDetail,refundGoodsList } from '@/request/bill'
// import sider from "@/components/sider/sider";
// import touTop from "@/components/touTop/touTop";
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import uncheckoutModal from "./uncheckoutModal";
import writeoffModal from "./writeoffModal";
// import ruleModal from "./ruleModal";
import paymentModal from "./paymentModal";
import drawbackModal from "./drawbackModal";
import refundModal from "./refundModal";
import Cookies from "js-cookie";
import {reserveInfo} from '@/request/reserve.js';
import { mapState } from "vuex";
export default {
  data() {
    return {
      showUncheckout: false, // 反结账modal
      showWriteoff: false, //销账modal
      showDrawback: false, //详情modal
      showRefund: false,  //退款
      members: [],
      items: [],
      columns:[],
      // headerName: "账单流水",
      // sideMenu: [{ key: 0, value: "账单流水" },{key: 1, value: "数据统计" }],
      siderName: "订单管理", //sider切换内容
      clickEveryGood: "",
      keywords: "",
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      current: 1, //表格当前页
      total: 0, //表格数据条数
      order_no: "", //订单号
      start_time: "", //开始时间
      end_time: "", //结算时间
      pay_status: "", //订单状态
      order_id: "", //订单id
      record: "", //每条订单的数据
      hang_mobile: "", //挂账人手机号
      start_timeSure:'',//最终开始时间
      end_timeSure:'',//最终结算时间
      pay_statusSure:'',//最终订单状态
      order_noSure: "", //最终订单号
      hang_mobileSure: "", //最终挂账人手机号
      FOOD_TYPE:'',
      tableList:[],//桌台列表
      table_id:'',//桌台id
      table_idSure:'',//桌台id
      dateArr:[],

    };
  },
  components: {
    // touTop,
    // sider,
    breadCrumb,
    uncheckoutModal, //反结账
    writeoffModal, //挂账
    // ruleModal,
    paymentModal,
    drawbackModal, //详情modal
    refundModal,
  },
  computed: {
    ...mapState([ 'permission']),
  },
  mounted() {
    this.FOOD_TYPE = localStorage.getItem('FOOD_TYPE') || 'feast'
    if(this.FOOD_TYPE === 'feast'){
      this.columns = [
        {
          title: "桌号",
          dataIndex: "table_name",
          width:'10%'
        },
        {
          title: "订单号",
          dataIndex: "order_no",
          ellipsis: true,
          width:'16%'
        },
        {
          title: "实收",
          dataIndex: "payed_price",
          scopedSlots: { customRender: "payed_price" },
          width:'12%'
        },
        {
          title: "时间",
          dataIndex: "order_time",
          ellipsis: true,
          width:'13%'
        },
        {
          title: "状态",
          dataIndex: "pay_status",
          ellipsis: true,
          scopedSlots: { customRender: "status" },
          width:'7%'
        },
        {
          title: "操作",
          dataIndex: "operate",
          ellipsis: true,
          scopedSlots: { customRender: "action" },
          width:'31%'
        },
      ];
      this.items = [
        { key: 20, name: "已结算" },
        { key: 10, name: "待结算" },
        { key: 60, name: "已退款" },
        { key: 30, name: "已结算-挂账" },
      ]
    }else{
      // this.columns.splice(0,1)
      this.columns = [
        {
          title: "订单号",
          dataIndex: "order_no",
          ellipsis: true,
          width:'16%'
        },
        {
          title: "实收",
          dataIndex: "payed_price",
          ellipsis: true,
          scopedSlots: { customRender: "payed_price" },
          width:'10%'
        },
        {
          title: "时间",
          dataIndex: "order_time",
          ellipsis: true,
          width:'13%'
        },
        {
          title: "状态",
          dataIndex: "pay_status",
          ellipsis: true,
          scopedSlots: { customRender: "status" },
          width:'10%'
        },
        {
          title: "操作",
          dataIndex: "operate",
          ellipsis: true,
          scopedSlots: { customRender: "action" },
          width:'22%'
        },
      ];
      this.items = [
        { key: 20, name: "已结算" },
        { key: 10, name: "待结算" },
        { key: 60, name: "已退款" },
      ]
    }
    var datetime = new Date();
    var year = datetime.getFullYear();
    var month = datetime.getMonth() + 1 < 10 ? '0' + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
    var date = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate();
    this.start_time=`${year}-${month}-${date}`
    this.end_time=`${year}-${month}-${date}`
    this.dateArr=[this.start_time,this.end_time]
    this.start_timeSure=this.start_time//最终开始时间
    this.end_timeSure=this.end_time//最终结算时间
    this.billListRequest();
    this.reserveRequest();
  },
  updated() {
    // this.showDrawback
  },
  methods: {
    changeModalStatu(data) {
      this.order_id=data && data.order_id;
      this.showWriteoff = true;
    },
    onChange(date, dateString) {
      this.start_time = dateString[0];
      this.end_time = dateString[1];
    },
    changeUncheckout(record) {
      //点击反结账
      if (record.is_anti === 1) {
        this.showUncheckout = true;
      }
      this.order_id = record.order_id;
    },
    refundHandle(record){
      //退款
      console.log('tuikuan ');
      
      if(record.is_refund == 1){
        this.record = record;
        refundGoodsList({order_id : this.record.order_id}).then(res => {
          if(res.data.status === 200){
            this.showRefund = true
          }
        })
      }
    },
    clooseRefund(){
      this.showRefund = false
      this.billListRequest();
    },
    changeWriteoff(record) {
      //点击销账
      this.record = record;
      this.order_id = record.order_id;
      if (record.is_hang === 1) {
        this.showWriteoff = true;
      }
    },
    printKedan(data){
      //打印客单
      console.log(data);
      this.socketApi.websocketsend({"key": "kedanAction",value:{token: Cookies.get("Access-Token"),founding_log_id:data.founding_log_id} });
    },
    clickDetail(record) {
      //点击详情
      // console.log(JSON.stringify(record));
      console.log(record);
      if(record.pay_status ==60 ){
        refundOrderDetail({order_id: record.order_id}).then(res => {
            if(res.data.status == 200){
              this.record = record;
              this.showDrawback = true;
            }else{
              this.$message.error(res.data.message)
            }
          })
      }else{
        this.record = record;
        this.showDrawback = true;
      }
    },
    changeUn(number) {
      //关闭反结账modal
      if (number[0]) {
        this.showUncheckout = false;
      }
      if (number[1]) {
        this.billListRequest();
      }
    },
    changeWriteo(number) {
      // 关闭销账modal
      console.log(number);
      if (number[0]) {
        this.showWriteoff = false;
      }
      if (number[1]) {
        this.billListRequest();
      }
    },
    changeModal() {
      this.showDrawback = false;
    },
    handleChange(value) {
      this.pay_status = value;
    },
    tableIDChoose(value){
      this.table_id=value;
    },
    billListRequest() {
      //列表请求
      const _this = this;
      billList({
        listRows: 10,
        page: _this.current,
        order_no: _this.order_noSure,
        start_time: _this.start_timeSure,
        end_time: _this.end_timeSure,
        pay_status: _this.pay_statusSure,
        hang_mobile: _this.hang_mobileSure,
        table_id:_this.table_idSure,
      })
        .then((res) => {
          if (res.data.status === 200) {
            const list = res.data.data.list && res.data.data.list;
            _this.total = res.data.data.list.total && res.data.data.list.total;
            const members = res.data.data.list.data && res.data.data.list.data;
            members.map((item) => {
              item.operate = {
                is_hang: item.is_hang,
                is_refund: item.is_refund,
                is_anti: item.is_anti,
              };
            });
            _this.members = members;
            // console.log(JSON.stringify(members))
          }
        })
        .catch((result) => {
          return false;
        });
    },
    handleTableChange(e) {
      //点击分页
      this.current = e.current;
      this.billListRequest();
    },
    searchMember() {
      this.current = 1;
      this.start_timeSure=this.start_time,//最终开始时间
      this.end_timeSure=this.end_time,//最终结算时间
      this.pay_statusSure=this.pay_status,//最终订单状态
      this.order_noSure=this.order_no, //最终订单号
      this.hang_mobileSure=this.hang_mobile, //最终挂账人手机号
      this.table_idSure=this.table_id//最终table——id
      this.billListRequest();
    },
    reserveRequest(){//列表请求
       reserveInfo({
        // storey_id:'',
        // is_temp:1,
        noPage: 'yes'
         })
        .then((res) => {
          if(res.data.status===200){
              this.tableList=res.data.data.list && res.data.data.list;
            }               
          })
        .catch(result => {
            return false;
      })
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mis {
  width: 100%;
  display: flex;
  height: 95.4vh;
  // height: 100%;
  overflow-y: auto;

}
.left {
  width: 17vw;
  background: rgba(46, 62, 78, 1);
}

/deep/.ant-calendar-picker-icon {
  top: 30%;
}
/deep/.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 0;
  font-size: 1.8vh;
  text-align: center;
}
/deep/.ant-table-tbody > tr > td {
  padding: 8px 8px;
}
/deep/td {
  font-size: 1.7vh;
}
.ant-table-wrapper {
  zoom: 1;
}
.ant-row {
  margin: 1.5vh;
}
/deep/.card-title {
  margin: 0 0 1.5vh 0;
  font-size: 2vh;
  margin-left: 0vw;
  font-weight: bold;
}
.gray {

  .active {
    color: rgba(158, 158, 158, 1);
  }
}
.heng {
  color: rgba(158, 158, 158, 1);
  position: absolute;
  left: -8px;
  top: -2px;
}
.button {
  margin-right: 1.5vw;
  position: relative;
}
.click,
.clicks {
  margin-left: 1.2vw;
}
.isclick {
  cursor: pointer;
}
.right{
  background: rgba(248, 248, 255, 1);
  padding: 2vh;
}
/deep/td{
  padding:1.5vh !important;
  background: white !important;
}  
/deep/th{
  background: rgba(248, 248, 255, 1) !important;
}
/deep/.input{
  .ant-input{
    width:15.5vw !important;
  }
}

</style>
