<template>
  <div class="refundModal">
    <a-modal :visible="isShow" title="退款" width="80%" @cancel="handlecancel"  :dialog-style="{ top: '50px' }">
      <div class="refund_body">
        <!-- <a-radio-group name="radioGroup" v-model="refund_type" @change="radioChange">
          <a-radio :value="1">只退款</a-radio>
          <a-radio :value="2">退货退款</a-radio>
        </a-radio-group> -->
        <div class="allRefund" v-if="refund_type == 1">
          <!-- 全部退款 -->
          <a-table :columns="columnsone" :data-source="allTable" bordered rowKey="order_goods_id" :pagination="false" :locale="{emptyText: '暂无数据'}">
            <template slot="goods_name" slot-scope="record">
                <div>{{record.goods_name}}</div>
              </template>
            <template slot="refund_total_num" slot-scope="record">
              <a-input-number v-model="record.refund_total_num" disabled/>
            </template>
            <template slot="refund_stock" slot-scope="record">
              <a-switch v-model="record.refund_stock" checked-children="是" un-checked-children="否"/>
            </template>
            <template slot="refund_price" slot-scope="record">
              {{record.refund_price}}
            </template>
          </a-table>
          <div class="myDiv">
            <p class="ptwo">退款金额：</p>
            <a-input-number v-model="orderData.payed_price" disabled :formatter="value => `￥ ${value}`"/>
          </div>
        </div>
        <!-- 部分退款 -->
        <div class="partRefund" v-else-if="refund_type == 2">
          <!-- 商品表格 -->
          <a-table :columns="columns" :data-source="tableData" bordered 
          rowKey="order_goods_id" :pagination="false" :rowSelection="{ selectedRowKeys: selectedRowKeys, onSelect: onSelectChange , onSelectAll : onSelectAll}"   :locale="{emptyText: '暂无数据'}">
            <template slot="refund_total_num" slot-scope="record">
              <a-input-number v-model="record.refund_total_num" disabled/>
            </template>
            <template slot="total_pay_price" slot-scope="text,record">
              <div>{{(record.grade_goods_price * 1 * record.total_num).toFixed(2)}}</div>
            </template>
            <template slot="refund_stock" slot-scope="record">
              <a-switch v-model="record.refund_stock" checked-children="是" un-checked-children="否" disabled/>
            </template>
          </a-table>
          <div>
            <p class="pone">退款操作</p>
            <!-- 退款表格 -->
            <a-table :columns="columnsone" :data-source="checkData" bordered rowKey="order_goods_id" :pagination="false" :locale="{emptyText: '暂无数据'}">
              <template slot="goods_name" slot-scope="record">
                <div style="display:flex;justify-content: space-between;">
                  <span>{{record.goods_name}}</span>
                  <a-icon type="close" class="textColor" style="cursor: pointer;" @click="deleteRow(record)"/>
                </div>
              </template>
              <template slot="refund_total_num" slot-scope="record">
                <a-input-number v-model="record.refund_total_num" :min="1" :max="record.total_num" @change="refund_numChange(record)" />
              </template>
              <template slot="refund_stock" slot-scope="record">
                <a-switch v-model="record.refund_stock" checked-children="是" un-checked-children="否"/>
              </template>
              <template slot="refund_price" slot-scope="record">
                {{record.refund_price}}
              </template>
            </a-table>
            <div class="myDiv">
              <p class="ptwo">退款金额：</p>
              <!-- <a-input-number v-if="checkData && checkData.length" v-model="partMoney" disabled :formatter="value => `￥ ${value}`"/> -->
              <a-input-number  :min="0" :max="orderData.payed_price * 1" v-model="refund_total_price" :formatter="value => `￥ ${value}`"/>
              <p class="pthree">(注:可自定义输入金额)</p>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div class="footerBtn">
          <a-button class="clearBtn" @click="handlecancel">取消</a-button>
          <a-button class="OkBtn" @click="handleOk">确定</a-button>
        </div>
      </template>
    </a-modal>
      <a-modal :dialog-style="{ top: '20vh' }" width="50%" :visible="EnterUserInfo" title="请填写接收人信息"  @cancel="EnterUserInfo =  false,refundForm={},loading=false">
        <div class="userForm">
          <a-form-model ref="refundForm" :model="refundForm" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
            <a-form-model-item ref="refund_name" label="接收人" prop="refund_name">
              <a-input v-model="refundForm.refund_name"/>
            </a-form-model-item>
            <a-form-model-item ref="refund_mobile" label="电话" prop="refund_mobile">
              <a-input v-model="refundForm.refund_mobile" />
            </a-form-model-item>
          </a-form-model>
        </div>
        <template slot="footer">
          <div class="footerBtn">
          <a-button class="clearBtn" @click="EnterUserInfo =  false,refundForm={},loading=false">取消</a-button>
          <a-button class="OkBtn" @click="refundHandle" :loading="loading">确定</a-button>
        </div>
        </template>
      </a-modal>
  </div>
</template>
<script>
import { refundGoodsList , refundOrder } from '@/request/bill'

const columns = [
  {
    title: "商品名称",
    dataIndex: "goods_name",
    key: "goods_name",
    align:'center'
  },
  {
    title: "规格",
    dataIndex: "spec_content",
    align:'center'
  },
  {
    title: "数量",
    dataIndex: "total_num",
    key: "total_num",
    width: 80,
    align:'center'
  },
  {
    title: "退货数量",
    key: "refund_total_num",
    scopedSlots: { customRender: 'refund_total_num' },
    align:'center'
  },
  {
    title: "是否退库存",
    key: "refund_stock",
    scopedSlots: { customRender: 'refund_stock' },
    align:'center'
  },
  {
    title: "实收金额",
    dataIndex: "total_pay_price",
    key: "total_pay_price",
    align:'center',
    scopedSlots: { customRender: 'total_pay_price' },
  },
];

const columnsone = [
  {
    title: "商品名称",
    key: "goods_name",
    scopedSlots: { customRender: 'goods_name' },
    align:'center'
  },
  {
    title: "规格",
    dataIndex: "spec_content",
    align:'center'
  },
  {
    title: "数量",
    dataIndex: "total_num",
    key: "total_num",
    width: 80,
    align:'center'
  },
  {
    title: "退货数量",
    key: "refund_total_num",
    scopedSlots: { customRender: 'refund_total_num' },
    align:'center'
  },
  {
    title: "是否退库存",
    key: "refund_stock",
    scopedSlots: { customRender: 'refund_stock' },
    align:'center'
  },
  {
    title: "退款金额",
    key: "refund_price",
    scopedSlots: { customRender: 'refund_price' },
    align:'center'
  },
];

export default {
  props:['isShow','orderData'],
  data() {
    const _this = this
    return {
      columns,
      columnsone,
      formLayout: "horizontal",
      allData:[],
      allTable:[],
      tableData:[],   
      checkData:[],
      refund_type: 2,
      EnterUserInfo:false,
      refundForm:{},
      rules:{
        refund_name: [
          { required: false, message: '接收人姓名不能为空', trigger: 'blur' }
        ],
        refund_mobile: [
          { required: false, message: '接收人电话不能为空', trigger: 'blur' },
          { pattern : /^\d{6,12}$/, message: '电话格式不正确', trigger: 'blur' }
        ],
      },
      selectedRowKeys:[],
      refund_total_price:0,
      loading: false,
    };
  },
  watch:{
    isShow(val){
      if(val){
        this.getTableData(this.refund_type)
      }
    },
    tableData(val){
        if(val.length==0){
          this.refundForm.refund_content = []
        }
    }

  },
  methods: {
    radioChange(val){
      this.getTableData(this.refund_type);
    },
    getTableData(type){
      refundGoodsList({order_id : this.orderData.order_id}).then(res => {
        if(res.data.status === 200){
          this.allData = res.data.data
          console.log(JSON.stringify(this.allData))
          if(this.allData.length==0){
            this.refund_total_price=this.orderData.payed_price
          }else{
             this.refund_total_price=0;
          }
          if(type == 1){
            this.allTable = []
            res.data.data.forEach(item => {
              this.$set(item , 'refund_total_num' , 0)
              this.$set(item , 'refund_price' ,  item.grade_goods_price * 1 * item.total_num)
              this.$set(item , 'refund_stock' , true)
              this.allTable.push(item)
            })
          }else{
            this.tableData = []
            this.checkData = []
            res.data.data.forEach(item => {
              this.$set(item , 'refund_total_num' , 0)
              this.$set(item , 'refund_stock' , false)
              this.tableData.push(item)
            })
          }
        }else{
          // setTimeout(() => {
          //   this.handlecancel()
          // },800)
        }
      })
    },
    handlecancel() {
      this.$emit('choose')
    },
    handleOk(e) {
      if(this.refund_type == 2 && this.checkData.length == 0 && this.refund_total_price == 0){
        this.$message.warning('请先选择要退款的菜品或输入退款金额')
        return
      }
      this.EnterUserInfo = true;
    },
    partMoney(){
      let num = 0
      if(this.checkData && this.checkData.length){
        this.checkData.forEach(item => {
          num += (item.grade_goods_price * 1 * item.refund_total_num )
        })
      }
      if(num > (this.orderData.payed_price * 1)){
        num = this.orderData.payed_price * 1
      }
      return (num * 1).toFixed(2)
    },
    refund_numChange(row){
      console.log(row);
      row.refund_price = (row.grade_goods_price * 1 * row.refund_total_num).toFixed(2)
      if(row.refund_total_num < row.total_num){
        let i = -1
        this.tableData.forEach((item,index) => {
          if(item.order_goods_id == row.order_goods_id){
            i = index
          }
        })
        if(i>-1){
          this.$set(this.tableData , i , row)
        }else{
          this.tableData.push(row)
          this.selectedRowKeys.push(row.order_goods_id)
        }
      }else{
        let i = -1
        this.tableData.forEach((item,index) => {
          if(item.order_goods_id == row.order_goods_id){
            i = index
          }
        })
        if(i>-1){
          this.tableData.splice(i,1);
          let key_i = this.selectedRowKeys.findIndex(item => row.order_goods_id == item)
          if(key_i > -1){
            this.selectedRowKeys.splice(key_i,1);
          }
        }
      }
      this.refund_total_price = this.partMoney()
    },
    deleteRow(row){
      let _this = this
      let ii = -1
      _this.tableData.forEach((item,index) => {
        if(item.order_goods_id == row.order_goods_id){
          ii = index
        }
      })
      let deleteData = {}
      _this.allData.forEach(ele => {
        if(ele.order_goods_id ==  row.order_goods_id){
          _this.$set(ele , 'refund_total_num' , 0)
          _this.$set(ele , 'refund_stock' , false)
          deleteData = ele
        }
      })
      if(ii > -1){
        _this.tableData[ii] = deleteData
      }else{
        _this.tableData.push(deleteData)
      }

      let i
      _this.checkData.forEach((item,index) => {
        if(item.order_goods_id == row.order_goods_id){
          i = index
        }
      })
      _this.checkData.splice(i,1);
      
      let key_i = this.selectedRowKeys.findIndex(item => row.order_goods_id == item)
      if(key_i > -1){
        _this.selectedRowKeys.splice(key_i,1);
      }
      this.refund_total_price = this.partMoney()
    },
    refundHandle(){
      this.$refs.refundForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if(this.refund_type == 1){  //全部退款
            this.refundForm.order_id = this.orderData.order_id
            this.refundForm.refund_type = this.refund_type
            this.refundForm.refund_total_price = parseFloat(this.orderData.payed_price)
            let arr = []
            this.allTable.forEach(item => {
              arr.push({
                goods_id:item.goods_id,
                order_goods_id : item.order_goods_id,
                goods_name : item.goods_name,
                refund_total_num : item.refund_total_num,
                refund_price : item.refund_price,
                is_stock_total: item.refund_stock ? 1 : 2
              })
            })
            this.refundForm.refund_content = arr
          }else{  //部分退款
            this.refundForm.order_id = this.orderData.order_id
            this.refundForm.refund_type = this.refund_type
            this.refundForm.refund_total_price = parseFloat(this.refund_total_price)
            if(this.checkData && this.checkData.length){
              let arr = []
              this.checkData.forEach(item => {
                arr.push({
                  goods_id:item.goods_id,
                  order_goods_id : item.order_goods_id,
                  goods_name : item.goods_name,
                  refund_total_num : item.refund_total_num,
                  refund_price : item.refund_price,
                  is_stock_total: item.refund_stock ? 1 : 2
                })
              })
              this.refundForm.refund_content = arr
            }
          }

          refundOrder(this.refundForm).then(res => {
              if(res.data.status === 200){
                this.$message.success('退款成功')
                this.EnterUserInfo = false
                 this.loading = false;
                this.handlecancel()
                this.refundForm={}
              }
            })
          
        }
      });
    },
    //表格复选框 取消/选中 某一行
    onSelectChange (row,check){
      let _this = this
          console.log("selectedRowKeys---------------onSelect",row,check,_this.selectedRowKeys);
      if(check){
        let i
        _this.tableData.forEach((item,index) => {
          if(item.order_goods_id == row.order_goods_id){
            _this.$set(row , 'refund_total_num' , row.total_num) 
            _this.$set(row , 'refund_price' , row.grade_goods_price * 1 * row.total_num)
            _this.checkData.push(row)
            i = index
          }
        })
        _this.tableData.splice(i,1); 
      }else{
        _this.tableData.forEach((item,index) => {
          if(item.order_goods_id == row.order_goods_id){
            _this.allData.forEach(ele => {
              if(ele.order_goods_id ==  row.order_goods_id){
                _this.$set(item , 'refund_total_num' , 0)
                _this.$set(item , 'refund_stock' , false)
                // item = ele
              }
            })
          }
        })
        let i
        _this.checkData.forEach((item,index) => {
          if(item.order_goods_id == row.order_goods_id){
            i = index
          }
        })
        _this.checkData.splice(i,1); 
        let key_i = this.selectedRowKeys.findIndex(item => row.order_goods_id == item)
        if(key_i > -1){
          this.selectedRowKeys.splice(key_i,1);
        }
      }
      this.refund_total_price = this.partMoney()
    },
    //表格复选框 全选/反选
    onSelectAll(selected, selectedRows, changeRows){
      console.log(selected, selectedRows, changeRows);
      if(selected){
        console.log('this.tableData----------',this.tableData);
        
        this.tableData.forEach(item => {{
          console.log('item ---',item);
          if(item.refund_total_num == 0){
            this.checkData.push(item)
          }
          item.refund_total_num = item.total_num
          this.$set(item , 'refund_price' , item.grade_goods_price * 1 * item.total_num)
          // item.refund_price = item.total_pay_price
        }})
        this.tableData = []
        this.selectedRowKeys = []
      }else{
        changeRows.forEach(item => {
          let i = this.checkData.findIndex(ele => ele.order_goods_id == item.order_goods_id)
          if(i>-1){
            this.checkData.splice(i,1);
          }
        })
        this.tableData.forEach(item => {
          item.refund_total_num = 0
        })
        this.selectedRowKeys = []
      }
      this.refund_total_price = this.partMoney()
    }
  },
};
</script>
<style lang="less" scoped>
.refundModal{
  font-size: 1.8vh;
}
.allRefund{
  padding: 15px 0;
}
.partRefund{
  padding: 15px 0;
}
.myDiv {
  display: flex;
  margin: 1vh 0 0 0;
  
}
.checkBox {
  margin-left: 5vh;
}
// /deep/.ant-input {
//   width: 15vh;
//   height: 3.8vh;
// }
.pone {
  margin-top: 2vh;
}
.ptwo {
  margin-top: 0.8vh;
}
.pthree {
  margin: 0.8vh 0 0 1.5vh;
  color: #9e9e9e;
  font-size: 1.4vh;
}
.refund_body{
  height: 62vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 6px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
  }
  &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 6px;
      background   : #AAAAAA;
  }
  &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 6px;
      background   : #E0DCDC;
  }
}
.footerBtn{
  text-align: center;
}
</style>